.library {
  position: fixed;
  top: 0;
  left: 0;
  width: 20rem;
  height: 100%;
  background: rgb(238, 238, 238);
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.205);
  overflow: scroll;
  transform: translateX(-100%);
  transition: all 0.2s ease;
  opacity: 0;
}

.library h2 {
  padding: 2rem;
}

.library-song:hover {
  background: rgb(218, 235, 255);
}

/* ::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(255, 183, 183);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(221, 221, 221);
} */

.selected {
  background: rgb(255, 230, 255);
}

.active {
  transform: translateX(0%);
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .library {
    width: 100%;
  }
}

/*Nav*/

nav {
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

nav button {
  background: transparent;
  border: none;
  cursor: pointer;
  border: 2px solid rgb(65, 65, 65);
  padding: 0.8rem;
  transition: all 0.3s ease;
}

nav button:hover {
  background: rgb(65, 65, 65);
  color: white;
}

@media screen and (max-width: 768px) {
  nav button {
    z-index: 10;
  }
}

/*Player*/
.player {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.time-control {
  width: 50%;
  display: flex;
  align-items: center;
}

.time-control input {
  width: 100%;
  background-color: transparent;
  cursor: pointer;
}

.time-control p {
  padding: 1rem;
  font-weight: 700;
}

.play-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 30%;
}

.play-control svg {
  cursor: pointer;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  height: 16px;
  width: 16px;
}

.track {
  background: lightblue;
  width: 100%;
  height: 1rem;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}

.animate-track {
  background: rgb(204, 204, 204);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .time-control {
    width: 90%;
  }

  .play-control {
    width: 80%;
  }
}

/*Song*/
.song-container {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.song-container h2 {
  padding: 3rem 1rem 1rem 1rem;
}

.song-container h3 {
  font-size: 1rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotateSong {
  animation: rotate 20s linear forwards infinite;
}


/*Custom*/


@keyframes rotate {
  from {
    transform: rotate(0deg); /* Start rotation from 0 degrees */
  }
  to {
    transform: rotate(360deg); /* Rotate to 360 degrees */
  }
}

/* Apply the animation to the image */
.cover-spin {
  animation: rotate 12s linear infinite; /* Rotate the image continuously */
}