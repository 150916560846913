main {
  display: flex;
  padding: 2rem;
  height: 100vh;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  img {
    margin-left: 2rem;
  }

  @media (max-width: 1170px) {
    flex-direction: column;

    img {
      margin: 2rem 0;
      width: 400px;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;

    img {
      margin: 2rem 0;
      width: 300px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: var(--blue);
    font-size: 2.25rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 1.8rem;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  p.message {
    color: var(--text-medium);
    padding: 2rem;
  }

  button {
    font-size: 1rem;
    max-width: 160px;
    width: 100%;
    padding: 0.8rem 2rem;
    background: var(--blue);
    color: var(--white);
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: filter 0.3s;

    &:hover {
      filter: brightness(0.7);
    }
  }
}