.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  height: 32px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 32px;
}

@media only screen and (max-width: 680px) {
  .square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    height: 20px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 20px;
  }
}

@media only screen and (max-width: 425px) {
  .square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    height: 16px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 16px;
  }
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

@media only screen and (max-width: 425px) {
  .game-info {
    display: none;
  }
}

.game-config {
  display: block;
  margin-bottom: 20px;
}
.fixed-size {
  width: 100px;
  display: inline-block;
}
.btn-bold {
  font-weight: bold;
}
.square-highlight {
  color: red;
  background-color: yellow;
  font-weight: bold;
}
.content {
}

.footer {
  position: fixed;
  display: block;
  width: 100%;
  background: rgb(197, 197, 197);
  text-align: center;
  bottom: 0;
}
