@tailwind base;
@tailwind components;
@tailwind utilities;

/* start - @font-face */
/* @font-face {
  font-family: "Caligna";
  src: url("/fonts/caligna/Caligna-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/helvetica-neue/helvetica-neue-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Caligna';
  src: url('./assets/fonts/caligna/Caligna-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Caladea';
  src: url('./assets/fonts/caladea/Caladea-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'Caligna';
  src: url('./assets/fonts/caligna/Caligna-Regular.otf') format('opentype');
} */
/* end - @font-face */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
