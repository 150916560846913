@import url("https://fonts.googleapis.com/css?family=Bungee");

a.access-denied-a {
  color: #2aa7cc;
  text-decoration: none;
}
a.access-denied-a:hover {
  color: white;
}

svg#robot-error {
  width: 50vw;
}
.lightblue {
  fill: #444;
}
.eye {
  cx: calc(115px + 30px * var(--mouse-x));
  cy: calc(50px + 30px * var(--mouse-y));
}
#eye-wrap {
  overflow: hidden;
}
.error-text {
  font-size: 120px;
}
.alarm {
  animation: alarmOn 0.5s infinite;
}

@keyframes alarmOn {
  to {
    fill: darkred;
  }
}
