/* @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap"); */
/* 
* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
}
*/


.bubble-inner .headerBar {
  width: 100%;
  position: fixed;
  z-index: 99999;
  background-color: white;
  display: flex;
  justify-content: center;
}

.bubble-inner .headerButton {
  cursor: pointer;
  font-size: 16px;
  margin: 6px 12px;
  font-weight: 1000;
  color: rgb(0, 74, 158);
  transition: opacity 0.2s ease;
}

.bubble-inner .headerButton:hover {
  opacity: 0.4;
}

.bubble-inner .bubbleUI {
  position: relative;
  background-color: rgba(0, 0, 0, 0) !important;
  width: 100%;
  max-width: 1000px;
  height: 500px;
  border-radius: 50px;
  background-color: white !important;
  overflow: hidden !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  margin-bottom: 10px;
}

.bubble-inner h1 {
  font-size: 40px;
  margin-top: 80px;
  width: 100%;
  text-align: center;
  font-weight: 1000;
}
.bubble-inner h2 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
}

.bubble-inner .actionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bubble-inner .action {
  background-color: white;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 14px;
  color: black;
  text-decoration: none;
  white-space: nowrap;
  transition: transform 0.2s ease;
}

.bubble-inner .action:hover {
  transform: scale(1.05);
}

.bubble-inner .action img {
  height: 30px;
  margin-left: 10px;
}

.bubble-inner h3 {
  margin: 10px 0 30px 0;
  padding-top: 20px;
  font-size: 20px;
  font-weight: 1000;
  text-align: center;
}
/* 
.bubble-inner .container {
  background-color: red;
  max-width: 100vw;
  max-height: 600px;
  overflow: scroll;
} */

.bubble-inner .companyBubble {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s ease;
}

.bubble-inner .companyBubble:hover {
  opacity: 0.5;
}

.bubble-inner .controlBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -10px;
}

.bubble-inner .control {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 30px;
  margin: 10px;
  border-radius: 14px;
}

.bubble-inner .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.bubble-inner .controlTitle {
  font-size: 16px;
}

.bubble-inner .controlValue {
  font-size: 16px;
  text-align: right;
}

.bubble-inner .top img {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 6px;
  right: 6px;
  opacity: 0.2;
  transition: opacity 0.2s ease;
  cursor: pointer;
}

.bubble-inner .top img:hover {
  opacity: 1;
}

.bubble-inner .bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble-inner .bottom input[type="range"] {
  width: 100%;
}

.bubble-inner .install {
  width: 260px;
  border-radius: 14px;
  overflow: hidden;
}

.bubble-inner .codeBlock {
  width: 100%;
  max-width: 650px;
  margin-bottom: 30px;
}

.bubble-inner h4 {
  margin-top: 60px;
  padding-top: 20px;
  margin-bottom: 60px;
  font-size: 34px;
  font-weight: 1000;
  text-align: center;
}

.bubble-inner .propDesc {
  font-size: 20px;

  text-align: center;
  max-width: 800px;
  margin-bottom: 80px;
}

.bubble-inner .comparisonBubbleUI {
  height: 400px;
  width: 400px;
  border-radius: 50px;
  background-color: white !important;
  overflow: hidden !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.bubble-inner .dummyBubble {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.bubble-inner .dummyBubbleText {
  font-size: 17px;
}

.bubble-inner .optionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  text-align: center;
}

.bubble-inner .optionName {
  font-size: 30px;
  font-weight: 1000;
  margin-bottom: 6px;
}

.bubble-inner .optionName span {
  font-weight: 0;
  opacity: 0.5;
}

.bubble-inner .optionDefault {
  opacity: 0.5;
  font-size: 16px;
  margin-bottom: 14px;
}

.bubble-inner .optionDesc {
  font-size: 20px;
  margin-bottom: 20px;
  max-width: 900px;
}

.bubble-inner .comparisonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.bubble-inner .beforeContainer,
.bubble-inner .afterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.bubble-inner .comparisonText {
  font-size: 24px;
  margin-bottom: 20px;
}

.bubble-inner .dimensionsBubbleUI {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 500px;
  background-color: white;
  border-radius: 40px;
}

.bubble-inner .dimensionLabel {
  position: absolute;
  font-size: 18px;
  color: white;
  font-weight: 1000;
}

.bubble-inner .dimensionRegion {
  position: absolute;
  font-size: 24px;
  font-weight: 1000;
  color: white;
}

.bubble-inner .optionsDesc {
  font-size: 20px;
  margin-top: -40px;
  margin-bottom: 80px;
  max-width: 600px;
}

.bubble-inner .dimensionsDesc {
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 30px;
  max-width: 800px;
}