/* 
 * @Author: CJ Ting
 * @Date: 2016-10-29 16:39:15
 * @Email: cj.ting@fugetech.com
 */

 body {
  font-size: 14px;
}

.gallery {
  width: 90%;
  max-width: 1176px;
  margin: auto;
}

only screen and (max-width:320px){
  .gallery {
    width: 96%;
    max-width: 1176px;
    margin: auto;
  }
}

.gallery ._loading {
  margin: 120px auto;
}

.gallery__header {
  text-align: center;
  font-family: nanum_penregular;
  font-size: 4rem;
  color: #484848;
}

.gallery__layout {
  margin-bottom: 20px;
  text-align: center;
}

.gallery__layout__btn {
  width: 20px;
  fill: gray;
  margin: 0 10px;
  cursor: pointer;
  transition: fill 0.5s;
}

.gallery__layout__btn:hover {
  fill: black;
}

.gallery__zoomin {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-out;
  background-size: cover;
}

.gallery__zoomin--hide {
  opacity: 0;
  pointer-events: none;
}

.gallery__layout__btn--active {
  fill: black;
}

.gallery__photo-wrapper {
  margin-bottom: 30px;
  position: relative;
}

.gallery__photo-container {
  position: relative;
  background: #eee;
  border-radius: 5px;
}

.gallery__photo-hover {
  cursor: zoom-in;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery__photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 5px;
}

.gallery__list-photos .gallery__photo-info,
.gallery__grid-photos .gallery__photo-info {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gallery__grid-photos .gallery__photo-info {
  opacity: 0;
  pointer-events: none;
}

.gallery__grid-photos .gallery__photo-info__like {
  position: absolute;
  top: 16px;
  right: 16px;
  background: #f0f0f0;
  opacity: 0.9;
}

.gallery__grid-photos .gallery__photo-info__person {
  color: #ccc;
  position: absolute;
  bottom: 28px;
  left: 20px;
}

.gallery__grid-photos .gallery__photo-info__person:hover {
  color: white;
}

.gallery__grid-photos .gallery__photo-info__download {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #f0f0f0;
  opacity: 0.9;
}

/* .gallery__photo-wrapper:hover .gallery__photo-info {
  opacity: 1;
  transition: opacity 0.5s;
  pointer-events: auto;
} */
.gallery__photo-wrapper .gallery__photo-info {
  opacity: 1;
  transition: opacity 0.5s;
  pointer-events: auto;
}

.gallery__photo-wrapper:hover .gallery__photo-hover {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0, transparent 40%, transparent 60%, rgba(0, 0, 0, 0.3));
}

.gallery__photo-info__person {
  /* color: #999; */
  color: #111;
}

.gallery__photo-info__person:hover {
  color: #111;
}

.gallery__photo-info__like {
  display: flex;
  align-items: center;
  margin: 0;
  color: #484848;
  fill: #777;
  border: solid 1px #ddd;
  padding: 4px 8px;
  border-radius: 5px;
}

.gallery__photo-info__like > i {
  /* color: #f15151; */
  color: #03c04a;
  margin-right: 5px;
}

.gallery__photo-info__download {
  color: #999;
  border: solid 1px #ddd;
  padding: 8px 15px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
}

.gallery__photo-info__download:hover {
  color: #111;
  border-color: #999;
}

.gallery__grid-photos {
  display: flex;
  justify-content: space-between;
  /* min-width: 300px; */
}

.gallery__grid-photos__grid {
  width: 32%;
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .gallery__grid-photos {
    display: flex;
    /* justify-content: space-between; */
    /* min-width: 300px; */
    flex-direction: column; 
  }
  .gallery__grid-photos__grid {
    width: 100%;
  }
}



/*--------------------------------------Loading--------------------------------*/
._loading,
._loading:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}
._loading {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(0,0,0, 0.2);
  border-right: 5px solid rgba(0,0,0, 0.2);
  border-bottom: 5px solid rgba(0,0,0, 0.2);
  border-left: 5px solid #000000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/*-------------------Checkbox-------------------*/

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}